<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-left">
      <strong>{{props.item.id}}</strong>
    </td>
    <td class="justify-left">
      <div class="talk-stage__name" :style="{ background: props.item.color}">
        <v-icon color="white">{{props.item.icon}}</v-icon>
        <span style="padding-left: 10px;">{{props.item.name || '- - -'}}</span>
      </div>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'TalkStages',
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
  .talk-stage__name {
    text-align: left;
    color: white;
    padding: 3px 10px;
    font-size: 18px;
    margin-right: 30px;
    border-radius: 5px;
    border-style: dotted;
    border-width: 1px;
  }
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
</style>